import React, { useEffect } from "react";
import "./css/error.scss";
import image from "../../assets/error.svg";

const Error = () => {
  return (
    <div className="err-overall">
      <div className="err-cont-1">
        <div className="err-cont-1-in">
          <img className="err-img-main" src={image} />
          <span>
            Oops... The link you clicked may be broken or the page may have been
            removed. We’re sorry.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Error;
