import React, { useReducer, useEffect } from "react";
import "./css/leadfeature.css";
import { useForm } from "react-hook-form";
import { getLeadsManagement } from "../../store/api";
import { updateState } from "../../store/slice/state";
import loader from "../../assets/loader2.gif";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { MdGroup } from "react-icons/md";

export default function LeadFeature({
  dependencies: { State, Dispatch, selectOptions, booth_id },
}) {
  const defaultFields = [
    {
      id: "first_name",
      name: "First name *",
      tag: "input",
      required: true,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your first name",
    },
    {
      id: "last_name",
      name: "Last name",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your last name",
    },
    {
      id: "email_address",
      name: "Email *",
      tag: "input",
      required: true,
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: "email",
      placeholder: "Enter your email address",
    },
    {
      id: "phone_code",
      name: "Phone code",
      tag: "select",
      required: false,
      pattern: null,
      type: "text",
      placeholder: "Select country code",
    },
    {
      id: "phone_no",
      name: "Phone",
      tag: "input",
      required: false,
      pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
      type: "tel",
      placeholder: "Enter your phone no",
    },
    {
      id: "company",
      name: "Company",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your company name",
    },
    {
      id: "designation",
      name: "Designation",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your designation",
    },
  ];

  let Status = {
    addMore: false,
    loader: false,
  };

  const leadReducer = (leadState, action) => {
    switch (action.type) {
      case "ADD_MORE_FIELDS": {
        return { ...leadState, addMore: action.value };
      }
      case "API_LOADER": {
        return {
          ...leadState,
          loader: action.state,
        };
      }
      default: {
        return leadState;
      }
    }
  };

  const [leadState, leadDispatch] = useReducer(leadReducer, Status);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email_address: "",
      phone_code: "",
      phone_no: "",
      company: "",
      designation: "",
    },
  });
  async function sendLead(data) {
    leadDispatch({ type: "API_LOADER", state: true });
    await getLeadsManagement({
      booth_id: booth_id,
      event_id: "99",
      send_email_to_exhibitor: "Yes",
      message_from: `This message is sent from (${window.location.href})`,
      ...data,
    })
      .then((res) => {
        console.log(res?.data);
        if (res?.data?.status === "success") {
          toast.success(res?.data?.message);
          Dispatch({ type: "UPDATE_LEAD_STATUS", value: true });
        } else if (res?.data?.status === "failed") {
          toast.error(res?.data?.message);
        }
        setTimeout(() => {
          Dispatch({
            type: "CHANGE_ONE_POPUP_STATE",
            name: "isLeadPanelOpen",
            value: !State?.isLeadPanelOpen,
          });
        }, 1500);
        leadDispatch({ type: "API_LOADER", state: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    reset({
      first_name: "",
      last_name: "",
      email_address: "",
      phone_code: "",
      phone_no: "",
      company: "",
      designation: "",
    });
  }, [isSubmitSuccessful]);
  return (
    <>
      <div className="lead-pop-overall">
        <div
          className="lead-pop-content"
          style={{
            backgroundColor:
              State?.html?.accent_secondary_color !== null &&
              typeof State?.html?.accent_secondary_color !== "undefined"
                ? State?.html?.accent_secondary_color
                : "#ffff",
          }}
        >
          <div className="lead-pop-top-plane">
            <div className="">
              <MdGroup
                style={{
                  color:
                    State?.html?.accent_primary_color !== null &&
                    typeof State?.html?.accent_primary_color !== "undefined"
                      ? State?.html?.accent_primary_color
                      : "#5412e0",
                  width: "1.2rem",
                }}
              />
            </div>
            <h3
              className="title"
              style={{
                color:
                  State?.html?.accent_primary_color !== null &&
                  typeof State?.html?.accent_primary_color !== "undefined"
                    ? State?.html?.accent_primary_color
                    : "#5412e0",
              }}
            >
              Hear from us
            </h3>
          </div>

          <form className="lead-pop-form" onSubmit={handleSubmit(sendLead)}>
            {defaultFields
              .slice(0, `${leadState.addMore == false ? 5 : 7}`)
              .map((onefield, index) => {
                return (
                  <>
                    {onefield?.tag === "select" ? (
                      <div key={index} className="field">
                        <label
                          id="field-title"
                          style={{
                            color:
                              State?.html?.accent_primary_color !== null &&
                              typeof State?.html?.accent_primary_color !==
                                "undefined"
                                ? State?.html?.accent_primary_color
                                : "#525252",
                          }}
                          htmlFor={onefield?.id}
                        >
                          {onefield?.name}
                        </label>
                        <select
                          {...register(onefield?.id, {
                            required: onefield?.required,
                            pattern: onefield?.pattern,
                          })}
                          type={onefield?.type}
                          className="field-input"
                          id={onefield?.id}
                        >
                          <option
                            style={{ fontSize: "0.6rem", fontWeight: "400" }}
                            value=""
                            selected
                          >
                            Select country code
                          </option>
                          {typeof selectOptions !== "undefined"
                            ? selectOptions?.map((option, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={option?.phone_country_code}
                                  >
                                    {option?.phone_country_code}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    ) : null}

                    {onefield?.tag === "input" ? (
                      <div key={index} className="field">
                        <label
                          id="field-title"
                          style={{
                            color:
                              State?.html?.accent_primary_color !== null &&
                              typeof State?.html?.accent_primary_color !==
                                "undefined"
                                ? State?.html?.accent_primary_color
                                : "#525252",
                          }}
                          htmlFor={onefield?.id}
                        >
                          {onefield?.name}
                        </label>
                        <input
                          placeholder={onefield?.placeholder}
                          {...register(onefield?.id, {
                            required: onefield?.required,
                            pattern: onefield?.pattern,
                          })}
                          type={onefield?.type}
                          className={
                            errors?.[onefield?.id]
                              ? "field-input-err"
                              : "field-input"
                          }
                          id={onefield?.id}
                        ></input>
                        {errors?.[onefield?.id] &&
                          (errors?.[onefield?.id].type === "required" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              This field is required !!
                            </span>
                          ) : errors?.[onefield?.id].type === "pattern" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              Invalid input !!
                            </span>
                          ) : null)}
                      </div>
                    ) : null}
                  </>
                );
              })}
            {leadState.addMore === false ? (
              <button
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  cursor: "pointer",
                  outline: "none",
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "0.2rem",
                  fontFamily: " Nunito Sans, sans-serif",
                  color:
                    State?.html?.accent_secondary_color !== null &&
                    typeof State?.html?.accent_secondary_color !== "undefined"
                      ? State?.html?.accent_secondary_color
                      : "#ffff",
                  backgroundColor:
                    State?.html?.accent_primary_color !== null &&
                    typeof State?.html?.accent_primary_color !== "undefined"
                      ? State?.html?.accent_primary_color
                      : "#5412e0",
                }}
                onClick={() => {
                  leadDispatch({ type: "ADD_MORE_FIELDS", value: true });
                }}
              >
                + ADD MORE
              </button>
            ) : null}
            <button
              type="submit"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                backgroundColor:
                  State?.html?.accent_primary_color !== null &&
                  typeof State?.html?.accent_primary_color !== "undefined"
                    ? State?.html?.accent_primary_color
                    : "#5412e0",
                outline: "none",
                textDecoration: "none",
                color: "white",
                border: "none",
                borderRadius: "0.2rem",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: 6,
                  fontWeight: "500",
                  fontFamily: " Nunito Sans, sans-serif",
                  color:
                    State?.html?.accent_secondary_color !== null &&
                    typeof State?.html?.accent_secondary_color !== "undefined"
                      ? State?.html?.accent_secondary_color
                      : "#ffff",
                }}
              >
                Send
              </p>
              {leadState?.loader === true ? (
                <img src={loader} width="20px" height="20px"></img>
              ) : null}
            </button>
          </form>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <u
              style={{
                cursor: "pointer",
                color:
                  State?.html?.accent_primary_color !== null &&
                  typeof State?.html?.accent_primary_color !== "undefined"
                    ? State?.html?.accent_primary_color
                    : "#525252",
                fontFamily: " Nunito Sans, sans-serif",
              }}
              onClick={() => {
                Dispatch({
                  type: "CHANGE_ONE_POPUP_STATE",
                  name: "isLeadPanelOpen",
                  value: !State?.isLeadPanelOpen,
                });
              }}
            >
              MAYBE LATER
            </u>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
