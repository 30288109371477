import { createSlice } from "@reduxjs/toolkit";

export const state = createSlice({
  name: "state",
  initialState: {
    boothData: {},
    agent_details: {
      Location_details: {
        city: "unknown",
        city_district: "unknown",
        country: "unknown",
        country_code: "unknown",
        neighbourhood: "unknown",
        postcode: "unknown",
        state: "unknown",
        state_district: "unknown",
        suburb: "unknown",
      },
      Longitude: "unknown",
      Latitude: "unknown",
      Browser_CodeName: "unknown",
      Browser_Name: "unknown",
      Cookies_Enabled: "unknown",
      Browser_Version: "unknown",
      Browser_Language: "unknown",
      Browser_Online: "unknown",
      Platform: "unknown",
      Device_name: "unknown",
      Handset: "unknown",
      OS: "unknown",
      Browser: "unknown",
    },
    raw: {},
    boothDetails: {},
    templateDetails: [],
    boothConfig: {},
    userDetails: {},
    boothLoaded: false,
    isLoggedIn: false,
    boothDetailsLoading: true,
    boothDetailsError: {},
    countryCodes: [],
  },
  reducers: {
    updateCountryCodes: (state, action) => {
      state.countryCodes = action.payload;
    },
    updateBoothDetails: (state, action) => {
      console.log(action);
      state.boothDetails = action.payload;
    },
    updateState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateState, updateBoothDetails, updateCountryCodes } =
  state.actions;

export default state.reducer;
