import React from "react";
// import { ReactComponent as ReactLogo } from "../assets/error.svg";
import { useSelector, useDispatch } from "react-redux";
import errorimg from "../../assets/error403.jpg";
import "./css/error_forbideen.scss";

const Error_forbidden = () => {
  let scrn = window.screen.width;
  const { boothDetails } = useSelector((state) => state.state);
  return (
    <div className="errfor-overall">
      <div className="errfor-cont">
        <div className="errfor-img-main">
          <img style={{ width: "100%", height: "auto" }} src={errorimg} />
        </div>
        <div className="errfor-values">
          <span>
            Oops...
            {boothDetails.Status_code
              ? boothDetails.Status_code == "403"
                ? boothDetails.messages
                : "Your subscription is inactive. Please renew your subscription or contact hello@veespaces.com for any help!"
              : null}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Error_forbidden;
