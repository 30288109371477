import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import BusinessCard, { loader } from "./components/pages/BusinessCard";
import Error from "./components/errorPages/Error";
import Error_forbidden from "./components/errorPages/Error403";
import App from "./App";
import { loader as businessCardLoader } from "./components/pages/BusinessCard";
import Redirect from "./components/Redirect";
export const Api_Key = "GHHrt#3492XWz2DgL0M28Zs$5";
console.log(process.env.REACT_APP_MODE);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />} errorElement={<Error />}>
        <Route
          path=":userId"
          element={<BusinessCard />}
          errorElement={<Error />}
          loader={businessCardLoader}
        />
        <Route path="p" element={<Redirect />} />
        <Route path="b" element={<Redirect />} />
        <Route path="403" element={<Error_forbidden />} />
        <Route path="404" element={<Error />} />
      </Route>
    </>,
  ),
  { basename: "/" },
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router}></RouterProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
